import { EgClickstreamData } from "@shared-ui/clickstream-analytics-context";

export const egClickstreamLinksPresentedEvent: EgClickstreamData = {
  event: {
    event_name: "links_component.presented",
    event_type: "Impression",
    event_version: "1.0.0",
    event_category: "",
  },
  experience: {
    page_name: "Destination Landing",
  },
  additional_context: {
    user_interface: {
      component_name: "links_component",
    },
  },
};

export const egClickstreamAlternateAccommodationsPresentedEvent: EgClickstreamData = {
  event: {
    event_name: "links_component.presented",
    event_type: "Impression",
    event_version: "1.0.0",
    event_category: "",
  },
  experience: {
    page_name: "Destination Landing",
  },
  additional_context: {
    user_interface: {
      component_name: "alternate_accommodations_component",
    },
  },
};
